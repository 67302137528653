import React, { useEffect, useState } from "react";
import {
  Chart as ChartJs,
  LinearScale,
  CategoryScale,
  BarElement,
} from "chart.js";
import datalabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import axios from "axios";

ChartJs.register(CategoryScale, BarElement, LinearScale);
ChartJs.register(datalabels);

const AcTypesChart = () => {
  const [chart, setChart] = useState([]);
  const [AcCount, setAcCount] = useState(3);

  const baseUrl = `https://aircon-api.hiveagency.tech/api/Reports/GetACTypeRequestCounts`;

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const maintenanceAreaIdsString =
          localStorage.getItem("MaintenanceAreaIds");
        const maintainceAreaId = maintenanceAreaIdsString
          .split(",")
          .map(Number);
        const response = await axios.post(
          baseUrl,
          {
            maintenanceAreaIds: maintainceAreaId,
            AcTypeNumber: AcCount,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        setChart(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchInfo();
  }, [baseUrl, AcCount]);

  var data = {
    labels: chart?.map((x) => x.ACTypeName),
    datasets: [
      {
        label: ` نوع المكيف`,
        data: chart?.map((x) => x.RequestCount),
        backgroundColor: [
          "#B558F6",
          "#29CB97",
          "#FEC400",
          "#4072EE",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
        ],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    indexAxis: "y",
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "أنواع المكيفات",
        },
      },
    },
    legend: {
      labels: {
        fontSize: 26,
      },
    },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "center",
        color: "black",
      },
    },
  };

  return (
    <div className="warrantyChart">
      <div className="warrantyChart-container container">
        <div className="d-flex justify-content-between">
          <h2>نوع المكيف</h2>
          <div className="div selection-box mb-3">
            <select
              className="custom-select w-100"
              id="inputGroupSelect01"
              value={AcCount}
              onChange={(e) => setAcCount(e.target.value)}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
              <option value={0}>جميع الانواع</option>
            </select>
          </div>
        </div>
        <div>
          <Bar data={data} height={400} options={options} />
        </div>
        <div className="labels d-block">
          {chart.map((x) => (
            <>
              <p>{x.ACTypeName}</p>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AcTypesChart;
