import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import axios from "axios";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "./addAcTypes.css";
import MobileNav from "../mobileNav/MobileNav";

const isValidAcType = (acType) => acType !== "";

const AddAcTypes = () => {
  const [acType, setAcType] = useState([]);
  const [acTypeError, setAcTypeError] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const url = `https://aircon-api.hiveagency.tech/api/ACTypes`;

  const submit = async (e) => {
    e.preventDefault();

    const acTypeIsValid = isValidAcType(acType);

    setAcTypeError(acTypeIsValid ? "" : "برجاء ادخال نوع المكيف");

    if (acTypeIsValid) {
      try {
        await axios.post(url, {
          Name: acType,
        });
        toast.success("تمت إضافة نوع المكيف بنجاح", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        getAllAcTypes();
        setAcType("");
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const getAllAcTypes = async () => {
    try {
      const response = await axios.get(
        "https://aircon-api.hiveagency.tech/api/ACTypes"
      );
      setData(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteAcTypes = async (acTypesId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
      backdrop: `rgba(0,0,123,0.4)`,
      customClass: {
        container: "my-swal",
      },
    }).then(async (willDelete) => {
      if (willDelete.value) {
        try {
          await axios.delete(
            `https://aircon-api.hiveagency.tech/api/ACTypes/${acTypesId}`
          );
          Swal.fire({
            title: "Deleted!",
            text: "The AC type has been deleted successfully",
            icon: "success",
            confirmButtonText: "Cool",
            timer: 2000,
          });
          getAllAcTypes();
        } catch (error) {
          // Extract the error message from the response object
          let errorMessage;
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMessage = error.response.data.message;
            console.log(errorMessage);
          } else {
            errorMessage = "An unexpected error occurred";
            console.log(errorMessage);
          }
          // Display the error message using SweetAlert2
          Swal.fire({
            title: "Error!",
            text: errorMessage,
            icon: "error",
            confirmButtonText: "Try Again",
          });
          setError(error.response?.data || {});
        }
      } else {
        Swal.fire({
          title: "Cancelled",
          text: "Your AC type is safe :)",
          icon: "info",
          confirmButtonText: "OK",
        });
      }
    });
  };

  useEffect(() => {
    getAllAcTypes();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      <MobileNav />
      <section className="addAcTypes mt-0">
        <Helmet>
          <meta charSet="utf-8" />
          <title>إضافة المكيفات</title>
          <link
            rel="canonical"
            href="https://generalhome.sa/dashboard/addAcTypes"
          />
        </Helmet>
        {loading ? (
          <div className="spinner-container">
            <ClipLoader
              className="loader"
              loading={loading}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
              color="#00b1ff"
            />
          </div>
        ) : (
          <div className="addAcTypes-container ">
            <form onSubmit={submit} className="acAddTypesForm">
              <div className="acAddTypes">
                <div className="inputBox">
                  <h6 htmlFor="acType">نوع المكيف:</h6>
                  <input
                    type="text"
                    placeholder="نوع المكيف"
                    value={acType}
                    onChange={(e) => setAcType(e.target.value)}
                  />
                  {acTypeError && <p className="text-danger">{acTypeError}</p>}
                </div>
              </div>
              <button>إضافة المكيف</button>
            </form>
            <div className="d-flex info">
              <h2 className="mx-2 mb-4">إضافة نوع المكيف:</h2>
              <h3 className="mb-3">{data.length}</h3>
            </div>
            <div className="table-responsive-sm overflow-auto">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="row">إسم المكيف</th>
                    <th scope="row" className="text-center">
                      التحكم
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((acType) => (
                    <tr key={acType.ACTypeId}>
                      <td>{acType.Name}</td>
                      <td className="text-center btn-holder">
                        <Link to={`/dashboard/addAcTypes/${acType.ACTypeId}`}>
                          تعديل
                        </Link>
                        <button
                          onClick={() => deleteAcTypes(acType.ACTypeId)}
                          className="mx-3"
                        >
                          حذف
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default AddAcTypes;
