import React, { useRef } from "react";
import MaintainceState from "../../components/charts/maintainceState/MaintainceState";
import WarrantyChart from "../../components/charts/warrantyChart/WarrantyChart";
import TechnicianChart from "../../components/charts/technicianChart/TechnicianChart";
import AcTypesChart from "../../components/charts/acTypesChart/AcTypesChart";
import { useReactToPrint } from "react-to-print";
import "./chartsReport.css";
import RegionChart from "../../components/charts/regionChart/RegionChart";
import MobileNav from "../../components/mobileNav/MobileNav";

const ChartsReport = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Report",
    onAfterPrint: () => console.log("print success"),
  });
  return (
    <>
      <MobileNav />
      <div className="chartsReport">
        <div className="chartsReport-container" ref={componentRef}>
          <div className="chartsHeder d-flex justify-content-between mx-5">
            <h2>الإحصائات</h2>
            <button className="printButton" onClick={handlePrint}>
              طباعة التقرير
            </button>
          </div>
          <div className="charts-box">
            <div className="">
              <WarrantyChart />
            </div>
            <div className="">
              <MaintainceState />
            </div>
            <div className="">
              <RegionChart />
              <TechnicianChart />
            </div>
            <div className="">
              <AcTypesChart />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChartsReport;
