import React from "react";
import { Outlet } from "react-router-dom";
import "./dashboard.css";
import Sidebar from "../../components/sidebar/Sidebar";

const Dashboard = () => {
  return (
    <section className="dashboard">
      <div className="dashboard-container">
        <div className="dashboard-right">
          <Sidebar />
        </div>
        <div className="dashboard-left">
          <Outlet />
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
