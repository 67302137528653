import React, { Fragment, useEffect, useRef, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import "./appointment.css";

const isValidRegionM = (regionM) => regionM !== "";
const isValidName = (name) => name.trim() !== "";
const isValidRegion = (region) => region.trim() !== "";
const isValidCity = (city) => city.trim() !== "";
const isValidDistrict = (district) => district.trim() !== "";
const isValidAcType = (acType) => acType !== "";
const isValidWarranty = (warranty) => warranty !== "";
const isValidSelectedFile = (selectedFile) => {
  if (!selectedFile) {
    return true;
  }
  const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
  return validImageTypes.includes(selectedFile.type);
};

const Appointment = () => {
  const mobileNumber = localStorage.getItem("mobileNumber");
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const [isSending, setIsSending] = useState(false);
  const [regionM, setRegionM] = useState("");
  const [name, setName] = useState("");
  const [region, setRegion] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [acType, setAcType] = useState("");
  const [warranty, setWarranty] = useState("");
  const [notice, setNotice] = useState("");
  const [data, setData] = useState([]);

  const [regionMError, setRegionMError] = useState("");
  const [nameError, setNameError] = useState("");
  const [regionError, setRegionError] = useState("");
  const [cityError, setCityError] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [acTypeError, setAcTypeError] = useState("");
  const [warrantyError, setWarrantyError] = useState("");
  const [selectedFileError, setSelectedFileError] = useState("");

  const myImageInputRef = useRef(null);

  const submit = async (e) => {
    e.preventDefault();

    const regionMIsValid = isValidRegionM(regionM);
    const nameIsValid = isValidName(name);
    const regionIsValid = isValidRegion(region);
    const cityIsValid = isValidCity(city);
    const districtIsValid = isValidDistrict(district);
    const acTypeIsValid = isValidAcType(acType);
    const warrantyIsValid = isValidWarranty(warranty);
    const selectedFileIsValid = isValidSelectedFile(selectedFile);

    setRegionMError(regionMIsValid ? "" : "برجاء اختيار المنطقه");
    setNameError(nameIsValid ? "" : "برجاء ادخال الاسم");
    setRegionError(regionIsValid ? "" : "برجاء ادخال اسم المنطقه");
    setCityError(cityIsValid ? "" : "برجاء ادخال المدينه");
    setDistrictError(districtIsValid ? "" : "برجاء ادخال اسم الحي");
    setAcTypeError(acTypeIsValid ? "" : "برجاء ادخال نوع المكيف");
    setWarrantyError(warrantyIsValid ? "" : "برجاء ادخال حالة الضمان");
    setSelectedFileError(
      selectedFileIsValid ? "" : "برجاء ادخال صيغة صوره صحيحه "
    );

    const formData = new FormData();
    formData.append("ImageFile", selectedFile);
    formData.append("MaintenanceAreaId", regionM);
    formData.append("CustomerName", name);
    formData.append("PhoneNumber", mobileNumber);
    formData.append("Region", region);
    formData.append("City", city);
    formData.append("Neighborhood", district);
    formData.append("ACTypeId", acType);
    formData.append("WarrantyStatusId", warranty);
    formData.append("ErrorType", notice);

    const apiUrl =
      "https://aircon-api.hiveagency.tech/api/maintenance/request";

    if (
      regionMIsValid &&
      nameIsValid &&
      regionIsValid &&
      cityIsValid &&
      districtIsValid &&
      acTypeIsValid &&
      warrantyIsValid &&
      selectedFileIsValid
    ) {
      setIsSending(true);
      try {
        await axios.post(apiUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        Swal.fire({
          icon: "success",
          text: "تم ارسال طلبك بنجاح",
        });
        setRegionM("");
        setName("");
        setRegion("");
        setCity("");
        setDistrict("");
        setAcType("");
        setWarranty("");
        setNotice("");
        setSelectedFile(null);
        if (myImageInputRef.current) {
          myImageInputRef.current.value = "";
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        setIsSending(false);
      }
    }
  };

  const getAllAcTypes = async () => {
    try {
      const url = `https://aircon-api.hiveagency.tech/api/ACTypes`;
      const response = await axios.get(url);
      setData(response.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const buttonRef = useRef(null);

  useEffect(() => {
    getAllAcTypes();
    if (buttonRef.current) {
      const button = buttonRef.current;
      if (button.disabled) {
        button.classList.add("disabled-style");
      } else {
        button.classList.remove("disabled-style");
      }
    }
  }, []);

  return (
    <section className="appointment">
      <Helmet>
        <meta charSet="utf-8" />
        <title>حجز طلب الصيانه</title>
        <link rel="canonical" href="https://generalhome.sa/appointment" />
      </Helmet>
      <div className="appointment-container container">
        <form onSubmit={submit}>
          <div>
            <div className="div selection-box mb-3">
              <select
                className="custom-select w-100"
                id="inputGroupSelect01"
                value={regionM}
                onChange={(e) => setRegionM(e.target.value)}
              >
                <option>اختر منطقه الصيانه</option>
                <option value={1}>صيانة منطقه الرياض</option>
                <option value={2}>صيانة منطقه القصيم</option>
              </select>
            </div>
            {regionMError && <p className="text-danger">{regionMError}</p>}
          </div>
          <div className="inputs-box inputs-box1">
            <div className="div">
              <label htmlFor="name">الاسم</label>
              <input
                placeholder="الاسم"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {nameError && <p className="text-danger">{nameError}</p>}
            </div>
            <div className="div phoneNum">
              <label htmlFor="number">رقم الهاتف</label>
              <input
                placeholder="رقم الهاتف (باللغه الانجليزيه)"
                type="text"
                value={mobileNumber}
                readOnly={true}
              />
            </div>
          </div>
          <div className="inputs-box">
            <div className="div">
              <label htmlFor="region">المنطقه</label>
              <input
                placeholder="المنطقه"
                type="text"
                value={region}
                onChange={(e) => setRegion(e.target.value)}
              />
              {regionError && <p className="text-danger">{regionError}</p>}
            </div>
            <div className="div">
              <label htmlFor="city">المدينه</label>
              <input
                placeholder="المدينه"
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
              {cityError && <p className="text-danger">{cityError}</p>}
            </div>
            <div className="div">
              <label htmlFor="district">الحي</label>
              <input
                className="w-100"
                placeholder="الحي"
                type="text"
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
              />
              {districtError && <p className="text-danger">{districtError}</p>}
            </div>
          </div>
          <div className="selectionList">
            <div className="selections-box">
              <div className="selection-box mb-3 w-100">
                <select
                  className="custom-select w-100"
                  onChange={(e) => setAcType(e.target.value)}
                  value={acType}
                >
                  <option>أختر نوع المكيف</option>
                  {data.map((item) => {
                    return (
                      <Fragment key={item.ACTypeId}>
                        <option value={item.ACTypeId}>{item.Name}</option>
                      </Fragment>
                    );
                  })}
                </select>
              </div>
              {acTypeError && <p className="text-danger">{acTypeError}</p>}
            </div>
            <div className="warrantyBox">
              <div className="div selection-box">
                <select
                  className="custom-select select w-100"
                  id="inputGroupSelect01"
                  value={warranty}
                  onChange={(e) => setWarranty(e.target.value)}
                >
                  <option>حالة الضمان</option>
                  <option value={1}>داخل الضمان</option>
                  <option value={2}>خارج الضمان</option>
                </select>
              </div>
              {warrantyError && <p className="text-danger">{warrantyError}</p>}
            </div>
          </div>
          <div>
            <label className="d-block mb-2">برجاء رفع صوره الفاتوره</label>
            <input
              type="file"
              name="image"
              className="form-control-file border-0"
              id="myImageInput"
              onChange={handleFileChange}
              ref={myImageInputRef}
            />
            {selectedFileError && (
              <p className="text-danger">{selectedFileError}</p>
            )}
          </div>
          <div className="textarea-box">
            <label htmlFor="textarea">نوع العطل</label>
            <textarea
              name="textarea"
              placeholder="ملاحظه"
              value={notice}
              onChange={(e) => setNotice(e.target.value)}
            ></textarea>
          </div>
          <div>
            <button
              ref={buttonRef}
              id="myButton"
              type="submit"
              disabled={isSending}
            >
              تأكيد
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Appointment;
