import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/Opensource-01.png";
import axios from "axios";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import "./register.css";

const isValidRegionM = (regionM) => regionM.trim() !== "";
const isValidUserName = (name) => name.trim() !== "";
const isValidmobile = (mobile) => mobile.trim() !== "";
const isValidPassword = (password) => password.trim() !== "";

const Register = () => {
  const [username, setUsername] = useState("");
  const [mobile, setmobile] = useState("");
  const [password, setPassword] = useState("");
  const [regionM, setRegionM] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [mobileError, setmobileError] = useState("");
  const [regionMError, setRegionMError] = useState("");

  const [error, setError] = useState("");

  const submit = async (event) => {
    event.preventDefault();

    const usernameIsValid = isValidUserName(username);
    const passwordIsValid = isValidPassword(password);
    const mobileIsValid = isValidmobile(mobile);
    const regionMIsValid = isValidRegionM(regionM);

    setUsernameError(usernameIsValid ? "" : "برجاء إدخال الإسم");
    setPasswordError(passwordIsValid ? "" : "برجاء إنشاء كلمه مرور");
    setmobileError(mobileIsValid ? "" : "برجاء إدخال رقم الهاتف");
    setRegionMError(regionMIsValid ? "" : "برجاء إختيار المنطقه");

    if (usernameIsValid && passwordIsValid && mobileIsValid && regionMIsValid) {
      try {
        const response = await axios.post(
          "https://aircon-api.hiveagency.tech/api/Authenticate/AddNewTechnician",
          {
            MaintenanceAreaId: regionM,
            Username: username,
            Password: password,
            Mobile: mobile,
          }
        );
        if (response.data.success) {
          toast.success("تم إنشاء الحساب  ", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setPassword("");
          setUsername("");
          setmobile("");
          setRegionM("");
        } else {
          setError(response.data.Message);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <section className="register">
      <Helmet>
        <meta charSet="utf-8" />
        <title>إنشاء حساب</title>
        <link rel="canonical" href="https://generalhome.sa/register" />
      </Helmet>
      <div className="register-container">
        <div className="logo">
          <a href="/">
            <img src={Logo} alt="Logo" />
          </a>
        </div>
        <form onSubmit={submit}>
          <div className="input">
            <input
              id="name"
              type="text"
              placeholder="إسم المستخدم"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
            />
            {usernameError && <p className="text-danger">{usernameError}</p>}
          </div>
          <div className="input">
            <input
              type="mobile"
              id="mobile"
              name="mobile"
              placeholder="المحمول "
              onChange={(e) => setmobile(e.target.value)}
              value={mobile}
            />
            {mobileError && <p className="text-danger">{mobileError}</p>}
          </div>
          <div className="input">
            <input
              id="password"
              type="password"
              placeholder="كلمة المرور"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            {passwordError && <p className="text-danger">{passwordError}</p>}
          </div>
          <div className="input">
            <div className="div selection-box mb-3 input">
              <select
                className="custom-select w-100 my-2 areaSelect"
                id="inputGroupSelect01"
                value={regionM}
                onChange={(e) => setRegionM(e.target.value)}
              >
                <option>المنطقه</option>
                <option value={1}>الرياض</option>
                <option value={2}>القصيم</option>
              </select>
            </div>
            {regionMError && <p className="text-danger">{regionMError}</p>}
          </div>
          {error && <p className="error">{error}</p>}
          <div>
            <button className="reg-btn" type="submit">
              إنشاء حساب
            </button>
          </div>
        </form>
        <Link to="/login">
          <button className="login">Login</button>
        </Link>
      </div>
    </section>
  );
};

export default Register;
